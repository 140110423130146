<template>
    <KCourse loader-class="MBcont" course-id="4" title="Съемный костюм XIX в." :items="items">
        <div class="MBtextcont">
            <p>В этом уроке мы с вами разберем, как аккуратно снять выкройку с куклы и как ее смоделировать. Т.е. результатом этого занятия должны быть готовые бумажные выкройки для панталон, подъюбника, нижнего и верхнего платья.</p>
            
            <h3>2.0 Введение</h3>
        </div>

        <VideoView video-id="1a59a1f5bdc74ece9745b0e2e9eb4588"/>

        <div class="MBtextcont">
            <h3>2.1 Создаем базу выкройки</h3>
        </div>
        
        <VideoView video-id="dc74ad8e35e440d089b51e6b7c50bf3c"/>

        <div class="MBtextcont">
            <h3>2.2 Переносим выкройку на бумагу</h3>
        </div>
        
        <VideoView video-id="79115c0cf52e4c36a13cab840d928d16"/>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Подготовка", url:"/mycourses/costume/1"},
                { title: "Снимаем выкройку", url:"/mycourses/costume/2"},
                { title: "Моделируем", url:"/mycourses/costume/3"},
                { title: "Ткань. Раскрой", url:"/mycourses/costume/4"},
                { title: "Нижнее белье", url:"/mycourses/costume/5"},
                { title: "Шьем полочку", url:"/mycourses/costume/6"},
                { title: "Дошиваем платье", url:"/mycourses/costume/7"},
                { title: "Красное платье", url:"/mycourses/costume/8"},
                { title: "Делаем обувь", url:"/mycourses/costume/9"},
                { title: "Ручные швы", url:"/mycourses/costume/10"},
                { title: "Машинные швы", url:"/mycourses/costume/11"},
                { title: "Утюжка", url:"/mycourses/costume/12"},
            ],
        }
        }
    }
</script>